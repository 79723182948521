import React from "react"
import Layout from "../components/Layout" 
import { graphql } from "gatsby"
import { useLocation } from "@reach/router"

const PageTemplate = ( { pageContext: { page } } ) =>
{

  let location = useLocation()
  const title = page.node.frontmatter.title;
  const path = page.node.frontmatter.path;
  const description = page.node.frontmatter.description;
  const date = page.node.frontmatter.date;
  const html = page.node.html;



  return (
    <>
      <Layout>
        <br/>
        <br/>
        
        <div className="page-wrapper">
          <h1>{title}</h1>
          <div className="content" dangerouslySetInnerHTML = {{ __html: html}} />

        </div>
      </Layout>
    </>
  )
}

export default PageTemplate
